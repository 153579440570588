import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    card,
    imageBox,
    ratio,
    content,
    details,
    detailLabel,
    detailSlash,
    titleText,
    leadText,
} from './work-card.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IWorkCard } from '../../models/work.model';
import { useTranslation } from '../../hooks/use-translation';
import useMedia from '../../hooks/use-media';

import RatioImage from '../atoms/ratio-image';

export interface IWorkCardProps {
    className?: string;
    workCard: IWorkCard;
    TitleTag?: React.ElementType;
    LeadTag?: React.ElementType;
}

const WorkCard: React.FC<IWorkCardProps> = ({
    className = '',
    workCard,
    TitleTag = 'h2',
    LeadTag = 'h3',
}) => {
    const { t } = useI18next();
    const { slug, media } = workCard;
    const { title, lead, clientContent, mediaContent } = useTranslation(
        workCard,
        translationKeys.workCard
    );
    const mediaItem = useMedia({
        media,
        relation: relations.thumbnailHorizontal,
        fallback: relations.thumbnailVertical,
    });

    return (
        <Link to={slug} className={`${className} ${card}`}>
            <RatioImage
                image={mediaItem.image}
                alt={mediaItem.alt}
                fallbackUrl={mediaItem.url}
                className={imageBox}
                ratioClass={ratio}
            />
            <div className={content}>
                {(clientContent || mediaContent) && (
                    <div className={details}>
                        {clientContent && (
                            <p>
                                <span className={detailLabel}>{t('work.client')}:</span>{' '}
                                {clientContent}
                            </p>
                        )}
                        {mediaContent && (
                            <>
                                <span className={detailSlash}>/</span>
                                <p>
                                    <span className={detailLabel}>{t('work.media')}:</span>{' '}
                                    {mediaContent}
                                </p>
                            </>
                        )}
                    </div>
                )}
                <TitleTag className={titleText} title={title}>
                    {title}
                </TitleTag>
                {lead && <LeadTag className={leadText}>{lead}</LeadTag>}
            </div>
        </Link>
    );
};

export default WorkCard;
