import React from 'react';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import { button } from './button.module.scss';

interface IButtonElementProps extends React.ComponentProps<'button'> {
    as?: 'button';
    href?: never;
    to?: never;
}

interface IAnchorElementProps extends React.ComponentProps<'a'> {
    as: 'externalLink';
    href: string;
    to?: never;
}

interface ILinkComponentProps extends React.ComponentProps<typeof Link> {
    as: 'link';
    href?: never;
    to: string;
}

export type TButtonProps = { className?: string } & (
    | IButtonElementProps
    | IAnchorElementProps
    | ILinkComponentProps
);

const Button: React.FC<TButtonProps> = ({ className = '', as = 'button', children, ...rest }) => {
    const Tag = getTag(as);
    return (
        <Tag className={`${button} ${className}`} {...rest}>
            {children}
        </Tag>
    );
};

function getTag(as: TButtonProps['as']): React.ElementType {
    if (as === 'externalLink') return 'a';
    if (as === 'link') return Link;
    return 'button';
}

export default Button;
