import React from 'react';

import { title, large, big, medium } from './title.module.scss';

export interface ITitleProps {
    className?: string;
    Tag?: React.ElementType;
    children: React.ReactNode;
    size?: 'medium' | 'large' | 'big';
}

const Title: React.FC<ITitleProps> = ({ Tag = 'h2', className = '', children, size = 'large' }) => {
    return <Tag className={`${title} ${className} ${getSizeClass(size)}`}>{children}</Tag>;
};

function getSizeClass(size: ITitleProps['size']): string {
    if (size === 'large') return large;
    if (size === 'medium') return medium;
    if (size === 'big') return big;
    return '';
}

export default Title;
