import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { layout, list, loading } from './works.module.scss';
import { WORK_PAGE_COUNT, WORK_INITIAL_CURRENT_PAGE } from '../config/consts';
import { translationKeys } from '../config/translation-keys';
import { IPagination } from '../models/pagination.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISitePageContext } from '../config/site-page.model';
import { IWorkCard } from '../models/work.model';
import { useTranslation } from '../hooks/use-translation';
import { useWorkCardsContext } from '../contexts/work-cards-context';
import { getNodes } from '../utils/get-nodes';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import MainLayout from '../layouts/main-layout';
import WorkList from '../components/organisms/work-list';

interface IWorksProps {
    readonly data: {
        allWork: IQueryAllResult<IWorkCard>;
    };
    readonly pageContext: ISitePageContext;
}

const Works: React.FC<IWorksProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { allWork } = data;
    const { items: apiWorks, pagination, status, getNextPage } = useWorkCardsContext();
    const works = getNodes(allWork);

    const { label } = useTranslation(pageContext, translationKeys.pageContext);
    const breadcrumbs = [getBreadcrumbItem(pageContext, 'label')];

    const showButton = checkIfShowMoreButton(allWork.totalCount, pagination);

    return (
        <MainLayout
            isVisibleHeaderBg={true}
            className={`${layout} ${status === 'loading' ? loading : ''}`}
            breadcrumbs={breadcrumbs}
            SEOProps={{ title: label }}
        >
            <WorkList
                className={list}
                works={[...works, ...apiWorks]}
                title={t('works.title.1')}
                subtitle={t('works.title.2')}
                titleProps={{ Tag: 'h1' }}
                cardProps={{ TitleTag: 'h2', LeadTag: 'h3' }}
                showButton={showButton}
                buttonText={t('works.button')}
                buttonProps={{ onClick: getNextPage }}
            />
        </MainLayout>
    );
};

function checkIfShowMoreButton(totalCount = WORK_PAGE_COUNT, pagination: IPagination): boolean {
    if (pagination.currentPage <= WORK_INITIAL_CURRENT_PAGE) {
        return totalCount > WORK_PAGE_COUNT;
    }
    return pagination.currentPage < pagination.pageCount;
}

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        allWork(sort: { fields: publishedAt, order: DESC }, limit: 4) {
            edges {
                node {
                    ...workCardFields
                }
            }
            totalCount
        }
    }
`;

export default Works;
