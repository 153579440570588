import React from 'react';

import { container, header, accent, list, button } from './work-list.module.scss';
import { gridMedium } from '../../styles/grid.module.scss';
import { IWorkCard } from '../../models/work.model';

import Button, { TButtonProps } from '../atoms/button';
import Title, { ITitleProps } from '../atoms/title';
import WorkCard, { IWorkCardProps } from '../molecules/work-card';

interface IWorkListProps {
    className?: string;
    works: IWorkCard[];
    title?: string;
    subtitle?: string;
    titleProps?: Omit<ITitleProps, 'className' | 'children'>;
    cardProps?: Pick<IWorkCardProps, 'LeadTag' | 'TitleTag'>;
    showButton?: boolean;
    buttonText?: string;
    buttonProps?: TButtonProps;
}

const WorkList: React.FC<IWorkListProps> = ({
    className = '',
    works,
    title,
    subtitle,
    titleProps,
    cardProps,
    showButton = true,
    buttonText,
    buttonProps = {},
}) => {
    return (
        <div className={`${container} ${gridMedium} ${className}`}>
            {title && (
                <Title className={header} {...titleProps}>
                    {title}
                    {subtitle && <span className={accent}>{subtitle}</span>}
                </Title>
            )}
            <ul className={list}>
                {works.map((work) => {
                    return (
                        <li key={work.workId}>
                            <WorkCard workCard={work} {...cardProps} />
                        </li>
                    );
                })}
            </ul>
            {showButton && buttonText && (
                <Button
                    {...buttonProps}
                    className={`${button} ${buttonProps?.className ? buttonProps.className : ''}`}
                >
                    {buttonText}
                </Button>
            )}
        </div>
    );
};

export default WorkList;
